import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { setCookie } from "@analytics/cookie-utils"
import Header from "@components/header"
import Footer from "@components/footer"
import CartDrawer from "@src/components/cart/cart-drawer"
import Fatmedia from "@src/analytics/Fatmedia"
import Newsletter from "@components/newsletter"
import { TokiWallet, TokiScript } from "@components/widgets/toki"
import { StyluxProvider } from "@src/components/stylux"
import DisplayedAlert from "@src/components/alert/display-alert"
import { listenKlaviyoForms } from "@src/utils/general"
import useFirstRender from "@src/hooks/useFirstRender"
import Backdrop from "@src/components/sidebar/backdrop"
import Sidebar from "@src/components/sidebar"
import ProductQuickView from "@src/components/product/quick-view"
import { useCartStore, useSiteSettingsStore } from "@src/hooks/useStore"
import { generateThumbnailJsonLd } from "@utils/seo"
import { JsonLd } from "gatsby-plugin-next-seo"

const Layout = ({ props, children }) => {

  const { initializeCart } = useCartStore(["initializeCart"])

  const { setSiteSettings, setAlert } = useSiteSettingsStore(["setSiteSettings", "setAlert"]);

  useFirstRender(() => {
    setSiteSettings({ ...props.pageContext.siteSettings })
  })
  
  useEffect(() => {
    initializeCart()
    listenKlaviyoForms(setAlert)
    const currentUrl = new URL(window.location.href)
    if (currentUrl.searchParams.get("_country")) {
      setCookie("_geoCountry", currentUrl.searchParams.get("_country"))
    }
  }, [])

  return (
    <>
      <DisplayedAlert />
      <StyluxProvider>
        <a
          href="#main"
          className="absolute top-[-40px] left-0 z-[60] p-2 bg-gray-900 text-white text-center rounded-md no-underline focus:top-0"
        >
          Skip to content
        </a>
        <Fatmedia />
        <Header {...{...props?.pageContext?.pageFrame, location: props?.location}} />
        <main
          id="main"
          className={`text-soft-black ${
            props?.pageContext?.hasTopPadding
              ? "max-xs:pt-[148px] pt-[140px] md:pt-30"
              : "pt-16 pt-15 md:pt-large"
          }`}
        >
          {children}
          <Newsletter {...props?.pageContext?.newsletterBanner} />
        </main>
        <Footer {...props?.pageContext?.pageFrame?.footerData} />
        <CartDrawer />
        <Sidebar />
        <TokiWallet />
        <TokiScript />
      </StyluxProvider>

      <ProductQuickView />

      <Backdrop />
      <JsonLd json={generateThumbnailJsonLd()} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
